<template>
  <div>
    <editor
      :value="value"
      @input="$emit('input', $event)"
      api-key="q6vfmf1ynterowvjbyk6b7lygyoe1f2zjvl00y3dk10n1f73"
      :init="{
        height: 500,
        menubar: 'insert',
        plugins: [
          'advlist autolink lists link charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table code help wordcount',
          'image table textcolor hr',
        ],
        toolbar: [
          'undo redo | formatselect | bold italic backcolor forecolor | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | image | table | removeformat | code',
        ]
      }"
    />
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
import FireBaseStorageUpload from '@/mixins/FireBaseStorageUpload'

export default {
  mixins: [
    FireBaseStorageUpload
  ],

  props: {
    value: {
      type: String,
      required: false,
    },
    directory: {
			type: String,
			required: false
		}
  },

  data() {
    return {
      imageBoxEventType: 'childList',
      imageBoxEventSelector: 'tox tox-silver-sink tox-tinymce-aux',
      imageBoxTitleSelector: '.tox-dialog__title',
      imageBoxTitle: 'Insert/Edit Image',
      uploadButton: null,
      sourceInput: null
    }
  },

  components: {
    editor: Editor,
  },

  computed: {
    filteredDirectory() {
			if ( this.directory ) {
				return this.directory.replace(/^\/+|\/+$/g, '')
			} else {
				return 'uploads'
			}
		}
  },

  methods: {

    /**
     * Initialize mutation observer to start detecting when TinyMCE dialogues open
     */
    initObserver() {
      const elementToObserve = document.querySelector('body')
      const observer = new MutationObserver((mutationsList) => {
        mutationsList.forEach((mutation) => {
          this.mutationResponse(mutation)
        })
      })
      observer.observe(elementToObserve, {subtree: true, childList: true})
    },

    /**
     * Determine what to do after detecting a mutation
     * @param {Object} mutation - Mutation object
     */
    mutationResponse(mutation) {
      if (
        mutation.type === this.imageBoxEventType &&
        mutation.target.classList.value === this.imageBoxEventSelector
      ) {
        const title = mutation.target.querySelector(this.imageBoxTitleSelector)
        if (
          title &&
          title.innerText === this.imageBoxTitle
        ) {
          this.insertUploadButton(mutation.target)
        }
      }
      return
    },

    /**
     * Insert upload button into DOM after the TinyMCE image dialogue opens
     * @param {Object} target - HTMLElement
     */
    insertUploadButton(target) {    
      const labelSelector = '.tox-label'
      const groupSelector = '.tox-form__group'
      const sourceText = 'Source'
      const sourceSelector = 'input[type="url"]'
      const buttonSelector = '.customUploadButton input'
      const buttonLabelSelector = '.customUploadButton label'

      const existingButtons = target.querySelectorAll(buttonSelector)
      if ( existingButtons.length ) {
        return
      }

      const labels = target.querySelectorAll(labelSelector)
      const buttonTemplate = `
        <div class="tox-form__controls-h-stack customUploadButton">
          <label>
            Upload
            <input name="customUpload" type="file">
          </label>
        </div>
      `

      labels.forEach((label) => {
        if (label.innerText !== sourceText) {
          return
        }

        const wrapper = label.closest(groupSelector)
        this.sourceInput = wrapper.querySelector(sourceSelector)

        if (!wrapper) {
          return
        }

        wrapper.insertAdjacentHTML('beforeend', buttonTemplate)

        this.uploadLabel = wrapper.querySelector(buttonLabelSelector)
        this.uploadButton = wrapper.querySelector(buttonSelector)
      })
    },

    /**
     * Validate file upload before saving to server
     * @param {Event} event - file input change
     */
    upload(event) {
      console.log('upload', event)
      const file = this.uploadButton.files[0]

      if (!file) {
        console.log('Failed to select file.')
        return
      }

      if (/^image\//.test(file.type)) {
        this.saveToServer(file)
      } else {
        console.log('You can only upload images.')
      }
    },

    /**
     * Save image to Firebase
     * @param {Object} file - the uploaded file
    */
    saveToServer(file) {
      this.uploadLabel.innerText = 'Uploading...'
			const fd = new FormData()
			fd.append('image', file)
			this.fireBaseStorageUpload(file, this.filteredDirectory)
		},
  },

  watch: {

    /**
     * Bind change event listener to any upload button inserted into DOM
     */
    uploadButton() {
      this.uploadButton.addEventListener('change', (event) => this.upload(event))
    },

    /**
     * Watch FireBaseStorageUpload mixin and insert the resulting URL in editor
     */
    fireBaseStoreageFile() {
      this.sourceInput.value = this.fireBaseStoreageFile.url
      this.uploadLabel.innerText = 'Upload'
			return this.fireBaseStoreageFile
		}
  },

  mounted() {
    this.initObserver()
  },
};
</script>

<style lang="scss" scoped>
</style>