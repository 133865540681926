var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('editor',{attrs:{"value":_vm.value,"api-key":"q6vfmf1ynterowvjbyk6b7lygyoe1f2zjvl00y3dk10n1f73","init":{
      height: 500,
      menubar: 'insert',
      plugins: [
        'advlist autolink lists link charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table code help wordcount',
        'image table textcolor hr' ],
      toolbar: [
        'undo redo | formatselect | bold italic backcolor forecolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | image | table | removeformat | code' ]
    }},on:{"input":function($event){return _vm.$emit('input', $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }